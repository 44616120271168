import { AxiosResponse } from 'axios';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { WrappedComponentProps } from 'react-intl';
import { DefaultState } from 'utils/types';

export function handleAxiosError(error: AxiosResponse, toastMessage: DefaultState['toastMessage'], formatMessage: WrappedComponentProps['intl']['formatMessage'], setError: UseFormSetError<FieldValues>) {
    if (error.status === 422 && error?.data?.message) {
        toastMessage(formatMessage({ id: "validation-message" }), 'error');
        Object.keys(error?.data?.message).forEach(err => {
            setError(err, {
                type: 'manual',
                message: error?.data?.message[err],
            });
        });
    } else {
        const errorMessage = JSON.stringify(error?.data?.message) || formatMessage({ id: "opps-error-message" });
        toastMessage(errorMessage, 'error');
    }
}
