import { services } from "redux/services";

const check = <T extends string>(
  collection: Record<T, keyof typeof services>
) => collection;

export const masterPaginationServices = check({
  property: "getAllProperty",
  blog: "getAllBlog",
  banner: "getAllBanner",
  role: "getAllRole",
  skill: "getAllSkill",
  spokenLanguage: "getAllSpokenLanguage",
  blogCategory: "getAllBlogCategory",
  blogTag: "getAllBlogTag",
  propertyTag: "getAllPropertyTag",
  amenities: "getAllAmenities",
  user: "getAllUser",
  categories: "getAllCategories",
  address: "getAllAddress",
  owner: "getAllOwner",
  partner: "getAllPartner",
  admin: "getAllAdmin",
  account: "getAllAccount",
  realtorCategory: "getAllRealtorCategory",
  propertyStatus: "getAllPropertyStatus",
  roadType: "getAllRoadType",
  areaUnit: "getAllAreaUnit",
  advertisement: "getAllAdvertisement",
  page: "getAllPage",
  contact: "getAllContact",
  subscriptionPlan: "getAllSubscriptionPlan",
});
