import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useIntl } from "react-intl";

interface Props {
  children:
    | React.ReactNode
    | React.ReactElement
    | React.ReactNode[]
    | React.ReactElement[];
  meta?: any;
  title?: string;
  hideToolbar?: boolean;
  isList?: boolean;
  onCreate?: () => void;
}

const PageLayout = forwardRef(
  (
    { children, isList, title, hideToolbar, meta, onCreate, ...other }: Props,
    ref: any
  ) => {
    const { formatMessage } = useIntl();
    const nagivate = useNavigate();
    let match = useLocation();
    return (
      <>
        <Helmet>
            <title>{`${title} | GREIA Admin Portal`}</title>
            <meta
              name="description"
              content="Manage your real estate listings and client interactions in the GREIA Admin Panel."
            />

            <meta
              name="keywords"
              content="GREIA, Admin panel, real estate management, property listings, client interactions"
            />

            <meta name="robots" content="index, follow" />

            <meta name="author" content="GREIA" />

            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            {meta}
        </Helmet>

        <Box ref={ref} {...other}>
          <Container maxWidth="xl" style={{ maxWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="h5" color="primary" gutterBottom>
                {title} {isList && formatMessage({ id: "list" })}
              </Typography>
              {!hideToolbar && (
                <Button
                  variant="contained"
                  onClick={() =>
                    onCreate ? onCreate() : nagivate(`${match.pathname}/create`)
                  }
                  startIcon={<Add />}
                >
                  Create
                </Button>
              )}
            </Stack>
            {children}
          </Container>
        </Box>
      </>
    );
  }
);

PageLayout.defaultProps = {
  title: "Home",
  hideToolbar: false,
  isList: true,
};

export default PageLayout;
