import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Container>
      <Helmet>
        <meta name="description" content="Oops! The page you are looking for could not be found. Please check the URL or navigate back to the homepage." />
        <meta name="keywords" content="GREIA, agent 404, page not found, real estate, error" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="author" content="GREIA" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling.
        </Typography>

        <Box
          component="img"
          src="/static/illustrations/illustration_404.svg"
          sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </ContentStyle>
    </Container>
  );
}
