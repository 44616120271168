import { DefaultReducerListType, DefaultReducerObjectType, TableFilterStateType, PaginationReducerType, AuthReducerObjectType, RecentRowReducerType } from "utils/types";

export const otpLength = 6;
export const genderTypeOptions = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Both', value: 3 },
];

export const constants = {
  df: "YYYY-MM-DD",
  dtf: "YYYY-MM-DD hh:mm A",
  tfAmPM: "hh:mm A",
  tsf: "hh:mm:ss",
  t24hf: "HH:mm",
  t24hsf: "HH:mm:ss",
}

export const tableInitialState: TableFilterStateType = {
  limit: 10,
  page: 1,
  sortKey: '',
  sortType: '',
  columnFilter: [],
  searchColumnFilters: [],
}

export const filterTypes = {
  "text": "STRING",
  "number": "NUMBER",
  "date": "DATE",
  "select": "SELECT",
  "multiSelect": "MULTI-SELECT",
  "boolean": "BOOLEAN",
  "custom": "CUSTOM"
}

export const filterOperators = {
  "asc": 0,
  "desc": 1,
  "isLessThan": 0,
  "isLessThanOrEqualTo": 1,
  "isEqualTo": 2,
  "isNotEqualTo": 3,
  "isGreaterThanOrEqualTo": 4,
  "isGreaterThan": 5,

  "startsWith": 6,
  "endsWith": 7,
  "contains": 8,
  "isContainedIn": 9,
  "doesNotContain": 10,
}

export const numberFiltersLists = [
  { label: "Is Less Than", operator: filterOperators.isLessThan },
  { label: "Is Less Than Or Equal To", operator: filterOperators.isLessThanOrEqualTo },
  { label: "Is Equal To", operator: filterOperators.isEqualTo },
  { label: "Is Not Equal To", operator: filterOperators.isNotEqualTo },
  { label: "Is Greater Than Or Equal To", operator: filterOperators.isGreaterThanOrEqualTo },
  { label: "Is Greater Than", operator: filterOperators.isGreaterThan },
];

export const dateFiltersLists = numberFiltersLists;

export const textFiltersLists = [
  { label: "Starts With", operator: filterOperators.startsWith },
  { label: "Ends With", operator: filterOperators.endsWith },
  { label: "Contains", operator: filterOperators.contains },
  { label: "Is Contained In", operator: filterOperators.isContainedIn },
  { label: "Does Not Contain", operator: filterOperators.doesNotContain }
];

export const defaultArrayState: DefaultReducerListType = {
  loading: false,
  error: false,
  data: [],
};

export const defaultObjectState: DefaultReducerObjectType = {
  loading: false,
  error: false,
  data: {},
};

export const AuthObjectState: AuthReducerObjectType = {
  loading: false,
  error: false,
  data: {},
  status: 0
};

export const defaultPaginationState: PaginationReducerType = {
  loading: false,
  error: false,
  data: {
    items: [],
    meta: { totalItems: 0, itemCount: 0, itemsPerPage: 0, totalPages: 0, currentPage: 0 }
  },
};

export const defaultRecentRowState: RecentRowReducerType = {
  loading: false,
  error: false,
  data: {
    recentModelItems: [],
  },
};

export const genderList = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Both', value: 'both' },
];

export const statusDropDownList = [
  { label: 'Available', value: 'available', color: "success" },
  { label: 'Unavailable', value: 'unavailable', color: "warning" },
  { label: 'Reserved', value: 'reserved', color: "primary" },
  { label: 'Sold', value: 'sold', color: "danger" },
];

export const booleanTypes = {
  Yes: true,
  No: false
}

export const positiveNegativeOptions = [
  { label: "Positive", value: 1 },
  { label: "Negative", value: 2 }
]

export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 2 }
]

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]

export const statusOptions = [
  { label: "In Active", value: "0", color: "error" },
  { label: "Active", value: "1", color: "secondary" },
]

export const blogStatusList = [
  { label: 'Pending', value: 'pending', color: "warning" },
  { label: 'Published', value: 'published', color: "secondary" },
  { label: 'Rejected', value: 'rejected', color: "error" },
  { label: 'Cancelled', value: 'cancelled', color: "error" },
];

export const propertyPurposeList = [
  { label: 'Sale', value: 'sale' },
  { label: 'Rent', value: 'rent' },
  { label: 'Lease', value: 'lease' },
];

export const propertyTypeList = [
  { label: 'Residental', value: 'residental' },
  { label: 'Commercial', value: 'commercial' },
  { label: 'Semi-Commercial', value: 'semi-commercial' },
  { label: 'Farm', value: 'farm' },
];

export const categoryTypeList = [
  { label: 'None', value: '' },
  { label: 'Bunglow', value: 'bunglow' },
  { label: 'Flat System', value: 'flat-system' },
];

export const adPlacementList = [
  { label: 'TOP', value: 'TOP' },
  { label: 'CENTER', value: 'CENTER' },
  { label: 'LEFT', value: 'LEFT' },
  { label: 'RIGHT', value: 'RIGHT' },
  { label: 'BOTTOM', value: 'BOTTOM' },
];

export const propertyBHKList = [
  { label: 'None', value: 0 },
  { label: '1 BHK', value: 1 },
  { label: '2 BHK', value: 2 },
  { label: '3 BHK', value: 3 },
  { label: '4 BHK', value: 4 },
  { label: '5 BHK', value: 5 },
  { label: '6 BHK', value: 6 },
];

export const propertyFaceList = [
  { label: 'East', value: 'east' },
  { label: 'West', value: 'west' },
  { label: 'South', value: 'south' },
  { label: 'North', value: 'north' },
];

export const propertyExtraList = (formatMessage: any) => [
  { label: formatMessage({ id: 'balcony' }), value: 'hasBalcony' },
  { label: formatMessage({ id: 'premium' }), value: 'isPremium' },
  { label: formatMessage({ id: 'furnishing' }), value: 'isFurnishing' },
  { label: formatMessage({ id: 'featured' }), value: 'isFeatured' },
  { label: formatMessage({ id: 'verified' }), value: 'isVerified' },
  { label: formatMessage({ id: 'publish' }), value: 'isActive' },
];

export enum OtpConstant {
  //OTP
  register = "register",
  forgotPassword = "forgotPassword",
  changepassword = "changepassword",
  validMinute = 10,
}

export const categoryOptionList = [
  { label: 'Letting', value: 'letting' },
  { label: 'New Homes', value: 'new-homes' },
  { label: 'Residental', value: 'residental' },
  { label: 'Commercial', value: 'commercial' },
  { label: 'Global Luxury', value: 'global-luxury' },
  { label: 'Timeshare', value: 'timeshare' },
  { label: 'Global Referral Network', value: 'global-referral-network' },
  { label: 'Other', value: 'other' },
];

export const roleOptionList = [
  { label: 'Developer', value: 'developer' },
  { label: 'Agency/Broker', value: 'agency' },
  { label: 'Buyer', value: 'buyer' },
  { label: 'Seller', value: 'seller' },
  { label: 'User', value: 'user' },
  { label: 'Other', value: 'other' },
];

