import { BASE_URI } from "utils/axios";

export const MASTER_API_ENDPOINTS = {
	// Master Requests
	LIST_ROLE: `${BASE_URI}/roles/list`,
	CREATE_ROLE: `${BASE_URI}/roles/save`,
	UPDATE_ROLE: `${BASE_URI}/roles/update`,
	DELETE_ROLE: `${BASE_URI}/roles/delete`,

	LIST_SKILL: `${BASE_URI}/skills/list`,
	CREATE_SKILL: `${BASE_URI}/skills/save`,
	UPDATE_SKILL: `${BASE_URI}/skills/update`,
	DELETE_SKILL: `${BASE_URI}/skills/delete`,

	LIST_SPOKEN_LANGUAGE: `${BASE_URI}/languages/list`,
	CREATE_SPOKEN_LANGUAGE: `${BASE_URI}/languages/save`,
	UPDATE_SPOKEN_LANGUAGE: `${BASE_URI}/languages/update`,
	DELETE_SPOKEN_LANGUAGE: `${BASE_URI}/languages/delete`,

	LIST_BLOG_CATEGORY: `${BASE_URI}/blog-categories/list`,
	CREATE_BLOG_CATEGORY: `${BASE_URI}/blog-categories/save`,
	UPDATE_BLOG_CATEGORY: `${BASE_URI}/blog-categories/update`,
	DELETE_BLOG_CATEGORY: `${BASE_URI}/blog-categories/delete`,

	LIST_BLOG_TAG: `${BASE_URI}/blog-tags/list`,
	CREATE_BLOG_TAG: `${BASE_URI}/blog-tags/save`,
	UPDATE_BLOG_TAG: `${BASE_URI}/blog-tags/update`,
	DELETE_BLOG_TAG: `${BASE_URI}/blog-tags/delete`,

	LIST_PROPERTY_TAG: `${BASE_URI}/tags/list`,
	CREATE_PROPERTY_TAG: `${BASE_URI}/tags/save`,
	UPDATE_PROPERTY_TAG: `${BASE_URI}/tags/update`,
	DELETE_PROPERTY_TAG: `${BASE_URI}/tags/delete`,

	LIST_AMENITIES_TAG: `${BASE_URI}/amenities/list`,
	CREATE_AMENITIES_TAG: `${BASE_URI}/amenities/save`,
	UPDATE_AMENITIES_TAG: `${BASE_URI}/amenities/update`,
	DELETE_AMENITIES_TAG: `${BASE_URI}/amenities/delete`,

	LIST_CATEGORY: `${BASE_URI}/categories/list`,
	CREATE_CATEGORY: `${BASE_URI}/categories/save`,
	UPDATE_CATEGORY: `${BASE_URI}/categories/update`,
	DELETE_CATEGORY: `${BASE_URI}/categories/delete`,

	LIST_PROPERTY_STATUS: `${BASE_URI}/property-status/list`,
	CREATE_PROPERTY_STATUS: `${BASE_URI}/property-status/save`,
	UPDATE_PROPERTY_STATUS: `${BASE_URI}/property-status/update`,
	DELETE_PROPERTY_STATUS: `${BASE_URI}/property-status/delete`,

	LIST_REALTOR_CATEGORY: `${BASE_URI}/realtor-categories/list`,
	CREATE_REALTOR_CATEGORY: `${BASE_URI}/realtor-categories/save`,
	UPDATE_REALTOR_CATEGORY: `${BASE_URI}/realtor-categories/update`,
	DELETE_REALTOR_CATEGORY: `${BASE_URI}/realtor-categories/delete`,

	LIST_ROAD_TYPE: `${BASE_URI}/road-type/list`,
	CREATE_ROAD_TYPE: `${BASE_URI}/road-type/save`,
	UPDATE_ROAD_TYPE: `${BASE_URI}/road-type/update`,
	DELETE_ROAD_TYPE: `${BASE_URI}/road-type/delete`,

	LIST_AREA_UNIT: `${BASE_URI}/area-units/list`,
	CREATE_AREA_UNIT: `${BASE_URI}/area-units/save`,
	UPDATE_AREA_UNIT: `${BASE_URI}/area-units/update`,
	DELETE_AREA_UNIT: `${BASE_URI}/area-units/delete`,

	LIST_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/list`,
	CREATE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/save`,
	UPDATE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/update`,
	DELETE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/delete`,

	LIST_CONTACT: `${BASE_URI}/contact/list`,
	DELETE_CONTACT: `${BASE_URI}/contact/delete`,

	FILE_UPLOAD: `${BASE_URI}/files`,
};
