import { Address } from 'pages';
import Dashboard from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Category, Forum, House, Image, PersonAdd, Settings, AccountBox, AnnouncementOutlined, Pages, ContactMail, Book, Tag, SubscriptionsOutlined, Subscriptions } from '@mui/icons-material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { roleData } from '../utils/constants/role';

const SidebarMenu = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: <Dashboard />,
    role: [roleData.superadmin, roleData.admin, roleData.agent, roleData.blog]
  },
  // {
  //   title: 'Account',
  //   path: '/admin/accounts',
  //   icon: <AccountBoxIcon />,
  //   role: [roleData.superadmin]
  // },
  {
    title: 'Banner',
    path: '/admin/banners',
    icon: <Image />,
    role: [roleData.superadmin]
  },
  // {
  //   title: 'Property',
  //   path: '/admin/properties',
  //   icon: <House />,
  //   role: [roleData.admin, roleData.agent, roleData.superadmin]
  // },
  // {
  //   title: 'Blog',
  //   path: '/admin/blogs',
  //   icon: <Forum />,
  //   role: [roleData.superadmin, roleData.admin, roleData.agent, roleData.blog]
  // },
  {
    title: 'Pages',
    path: '/admin/pages',
    icon: <Pages />,
    role: [roleData.superadmin]
  },
  {
    title: 'Users',
    path: '/admin/customers',
    icon: <AccountBox />,
    role: [roleData.superadmin]
  },
  {
    title: 'Subscription Plans',
    path: '/admin/subscription-plans',
    icon: <Subscriptions />,
    role: [roleData.superadmin]
  },
  // {
  //   title: 'Partner',
  //   path: '/admin/partner',
  //   icon: <SupervisedUserCircleIcon />,
  //   role: [roleData.superadmin]
  // },
  // {
  //   title: 'Advertisement',
  //   path: '/admin/advertisement',
  //   icon: <AnnouncementOutlined />,
  //   role: [roleData.superadmin]
  // },
  // {
  //   title: 'Owner',
  //   path: '/admin/owners',
  //   icon: <PersonAdd />,
  //   role: [roleData.admin,roleData.agent]
  // },
  // {
  //   title: 'Users',
  //   path: '/admin/account-users',
  //   icon: <AccountCircleIcon />,
  //   role: [roleData.superadmin, roleData.admin]
  // },
  {
    title: 'Inquiry',
    path: '/admin/inquiry',
    icon: <ContactMail />,
    role: [roleData.superadmin, roleData.admin, roleData.agent]
  },
  {
    title: 'Master',
    path: '/admin/settings',
    icon: <Settings />,
    role: [roleData.superadmin],
    children: [
      // {
      //   title: 'Amenities',
      //   path: '/admin/settings/amenities',
      //   icon: <GroupIcon />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Tags',
      //   path: '/admin/settings/property-tags',
      //   icon: <GroupIcon />,
      //   role: [roleData.superadmin]
      // },
      {
        title: 'Category',
        path: '/admin/settings/category',
        icon: <Category />,
        role: [roleData.superadmin]
      },
      // {
      //   title: 'Address',
      //   path: '/admin/settings/address',
      //   icon: <Address />,
      //   role: [roleData.superadmin]
      // },
      {
        title: 'Realtor Categories',
        path: '/admin/settings/realtor-categories',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      // {
      //   title: 'Property Status',
      //   path: '/admin/settings/property-status',
      //   icon: <GroupIcon />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Road Type',
      //   path: '/admin/settings/road-type',
      //   icon: <GroupIcon />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Area Unit',
      //   path: '/admin/settings/area-units',
      //   icon: <GroupIcon />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Blog Tags',
      //   path: '/admin/settings/blog-tags',
      //   icon: <Tag />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Blog Category',
      //   path: '/admin/settings/blog-categories',
      //   icon: <Book />,
      //   role: [roleData.superadmin]
      // },
      {
        title: 'Role',
        path: '/admin/settings/roles',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },

    ]
  }
];

export default SidebarMenu;
