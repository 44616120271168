import { avatarOptions, ChipColumnOptions, dateOptions, priceOptions } from ".";
import { blogStatusList, statusOptions } from "..";
import parse from 'html-react-parser';

export const masterColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const roleColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  // {
  //   name: "guard_name",
  //   label: formatMessage({ id: "guard-name" }),
  // },
];

export const accountColumns = (formatMessage: any) => [
  {
    name: "logo",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
];

export const blogColumns = (formatMessage: any) => [
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    ...avatarOptions
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(blogStatusList, { variant: "filled" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const pageColumns = (formatMessage: any) => [
  {
    name: "url",
    label: formatMessage({ id: "url" }),
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "createdAt",
    label: formatMessage({ id: "created-at" }),
    ...dateOptions
  },
];

export const bannerColumns = (formatMessage: any) => [
  {
    name: "files",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value?.[0]?.url} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "description",
    label: formatMessage({ id: "description" }),
    options: {
      customBodyRender: (value: any) => {
        return value ? parse(value) : '-';
      },
    },
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const propertyColumns = (formatMessage: any) => [
  {
    name: "thumbnail",
    label: formatMessage({ id: "thumbnail" }),
    ...avatarOptions
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    options: {
      setCellProps: () => ({ style: { minWidth: 300, maxWidth: 300 } }),
    },
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
  },
  {
    name: "price",
    label: formatMessage({ id: "price" }),
    ...priceOptions
  },
  {
    name: "createdAt",
    label: formatMessage({ id: "created-at" }),
    ...dateOptions
  },
];


export const ownerColumns = (formatMessage: any) => [
  {
    name: "photo",
    label: formatMessage({ id: "photo" }),
    ...avatarOptions
  },
  {
    name: 'fullName',
    label: formatMessage({ id: "name" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    name: 'email', label: formatMessage({ id: "email" }),
    hideGlobalSearchFilter: true,
  },
  {
    name: "address",
    label: formatMessage({ id: "address" }),
  },
];

export const userColumns = (formatMessage: any) => [
  {
    name: 'userName', label: formatMessage({ id: "username" }),
  },
  {
    name: 'fullName', label: formatMessage({ id: "name" }),
  },
  {
    name: 'email', label: formatMessage({ id: "email" }),
    hideGlobalSearchFilter: true,
  },
  {
    name: 'roles', label: formatMessage({ id: "role" }),
    options: {
      customBodyRender: (value: any) => {
        return <span>{value?.map((item: any) => item.name)}</span>;
      },
    },
    hideGlobalSearchFilter: true,
  },
]

export const adminColumns = (formatMessage: any) => [
  {
    name: 'userName', label: formatMessage({ id: "username" }),
  },
  {
    name: 'fullName',
    label: formatMessage({ id: "name" }),
    primaryColumnName: "firstName"
  },
  {
    name: 'email', label: formatMessage({ id: "email" }),
    hideGlobalSearchFilter: true,
  },
  {
    name: 'roles', label: formatMessage({ id: "role" }),
    options: {
      customBodyRender: (value: any) => {
        return <span>{value?.map((item: any) => item.name)}</span>;
      },
    },
    hideGlobalSearchFilter: true,
  },
]

export const amenitiesColumns = (formatMessage: any) => [
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const categoryColumns = (formatMessage: any) => [
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const partnerColumns = (formatMessage: any) => [
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const advertisementColumns = (formatMessage: any) => [
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    options: {
      customBodyRender: (value: any) => {
        return <img src={value} style={{ height: 50 }} />;
      },
    },
    hideGlobalSearchFilter: true,
    disableFilter: true
  },
  {
    name: "pageName",
    label: formatMessage({ id: "page" }),
  },
  {
    name: "placement",
    label: formatMessage({ id: "placement" }),
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "sequence",
    label: formatMessage({ id: "sequence" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const addressColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "municipality",
    label: formatMessage({ id: "municipality" }),
    options: {
      customBodyRender: (value: any) => {
        return value?.name;
      },
    }
  },
  {
    name: "wardNo",
    label: formatMessage({ id: "ward-no" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const contactColumns = (formatMessage: any) => [
  {
    name: "fullName",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
  },
  {
    name: "message",
    label: formatMessage({ id: "message" }),
  },
];


export const realtyCategoryColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "description",
    label: formatMessage({ id: "description" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const subscriptionPlanColumns = (formatMessage: any) => [
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "descriptions",
    label: formatMessage({ id: "description" }),
  },
  {
    name: "isActive",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];