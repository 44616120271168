import { BASE_URI } from "utils/axios";

export const API_ENDPOINTS = {
	LOGIN: `${BASE_URI}/auth/agent/login`,
	REGISTER: `${BASE_URI}/auth/agent/register`,
	GET_ACCOUNT: `${BASE_URI}/get-account`,
	SELECT_ACCOUNT: `${BASE_URI}/select-account`,
	SEND_OTP: `${BASE_URI}/auth/send-otp`,
	VERIFY_OTP: `${BASE_URI}/auth/verify-otp`,
	RESET_PASSWORD: `${BASE_URI}/auth/reset-password`,
	LOGOUT: `${BASE_URI}/logout`,
	DASHBOARD: `${BASE_URI}/dashboard`,
	PROFILE: `${BASE_URI}/agent/profile`,

	LIST_PROPERTY: `${BASE_URI}/properties/list`,
	GET_PROPERTY_BY_UUID: `${BASE_URI}/properties/get`,
	CREATE_PROPERTY: `${BASE_URI}/properties/save`,
	UPDATE_PROPERTY: `${BASE_URI}/properties/update`,
	DELETE_PROPERTY: `${BASE_URI}/properties/delete`,
	PROPERTY_LOOKUP: `${BASE_URI}/properties/lookup`,
	UPLOAD_PROPERTY_FILE_: `${BASE_URI}/properties/upload-files`,
	DELETE_PROPERTY_FILE: `${BASE_URI}/properties/delete-files`,
	UPDATE_PROPERTY_FEATURE: `${BASE_URI}/properties/update-properties`,

	LIST_BANNER: `${BASE_URI}/banners/list`,
	GET_BANNER_BY_UUID: `${BASE_URI}/banners/get`,
	CREATE_BANNER: `${BASE_URI}/banners/save`,
	UPDATE_BANNER: `${BASE_URI}/banners/update`,
	DELETE_BANNER: `${BASE_URI}/banners/delete`,

	LIST_BLOG: `${BASE_URI}/blogs/list`,
	GET_BLOG_BY_UUID: `${BASE_URI}/blogs/get`,
	CREATE_BLOG: `${BASE_URI}/blogs/save`,
	UPDATE_BLOG: `${BASE_URI}/blogs/update`,
	DELETE_BLOG: `${BASE_URI}/blogs/delete`,
	UPDATE_BLOG_STATUS: `${BASE_URI}/blogs/update-status`,
	BLOG_LOOKUP: `${BASE_URI}/blogs/lookup`,
	
	LIST_USER: `${BASE_URI}/users/list`,
	GET_USER_BY_UUID: `${BASE_URI}/users/get`,
	CREATE_USER: `${BASE_URI}/users/save`,
	UPDATE_USER: `${BASE_URI}/users/update`,
	UPDATE_USER_FLAG: `${BASE_URI}/users/update-flag`,
	DELETE_USER: `${BASE_URI}/users/delete`,
	USER_LOOKUP: `${BASE_URI}/users/lookup`,

	LIST_ADDRESS: `${BASE_URI}/addresses/list`,
	GET_ADDRESS_BY_UUID: `${BASE_URI}/addresses/get`,
	CREATE_ADDRESS: `${BASE_URI}/addresses/save`,
	UPDATE_ADDRESS: `${BASE_URI}/addresses/update`,
	DELETE_ADDRESS: `${BASE_URI}/addresses/delete`,
	ADDRESS_LOOKUP: `${BASE_URI}/addresses/lookup`,

	LIST_OWNER: `${BASE_URI}/owners/list`,
	GET_OWNER_BY_UUID: `${BASE_URI}/owners/get`,
	CREATE_OWNER: `${BASE_URI}/owners/save`,
	UPDATE_OWNER: `${BASE_URI}/owners/update`,
	UPDATE_OWNER_FLAG: `${BASE_URI}/owners/update-flag`,
	DELETE_OWNER: `${BASE_URI}/owners/delete`,

	LIST_PARTNER: `${BASE_URI}/partner/list`,
	CREATE_PARTNER: `${BASE_URI}/partner/save`,
	UPDATE_PARTNER: `${BASE_URI}/partner/update`,
	DELETE_PARTNER: `${BASE_URI}/partner/delete`,

	LIST_ADVERTISEMENT: `${BASE_URI}/advertisements/list`,
	CREATE_ADVERTISEMENT: `${BASE_URI}/advertisements/save`,
	UPDATE_ADVERTISEMENT: `${BASE_URI}/advertisements/update`,
	DELETE_ADVERTISEMENT: `${BASE_URI}/advertisements/delete`,

	LIST_ADMIN: `${BASE_URI}/admins/list`,
	GET_ADMIN_BY_UUID: `${BASE_URI}/admins/get`,
	CREATE_ADMIN: `${BASE_URI}/admins/save`,
	UPDATE_ADMIN: `${BASE_URI}/admins/update`,
	DELETE_ADMIN: `${BASE_URI}/admins/delete`,
	UPDATE_ADMIN_PROFILE: `${BASE_URI}/admins/update-profile`,
	
	LIST_ACCOUNT: `${BASE_URI}/accounts/list`,
	GET_ACCOUNT_BY_UUID: `${BASE_URI}/accounts/get`,
	CREATE_ACCOUNT: `${BASE_URI}/accounts/save`,
	UPDATE_ACCOUNT: `${BASE_URI}/accounts/update`,
	DELETE_ACCOUNT: `${BASE_URI}/accounts/delete`,

	LIST_PAGE: `${BASE_URI}/pages/list`,
	GET_PAGE_BY_UUID: `${BASE_URI}/pages/get`,
	CREATE_PAGE: `${BASE_URI}/pages/save`,
	UPDATE_PAGE: `${BASE_URI}/pages/update`,
	DELETE_PAGE: `${BASE_URI}/pages/delete`,

	CREATE_SETTING:`${BASE_URI}/setting/save`,
	GET_SETTING:`${BASE_URI}/setting/get`,
};
