import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Box, Button, Grid, IconButton } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { handleAxiosError, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { categoryOptionList, FileAcceptData, fileValidate, roleOptionList } from 'utils/constants';
import { Add, Delete } from '@mui/icons-material';
import CustomTextEditor from 'components/forms/CustomTextEditor';
import { features } from 'process';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const pricesInitialState = {
    planPeriod: 1,
    periodType: 'year',
    amount: 0,
    saveAmount: 0,
    totalAmount: 0,
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, watch, clearErrors, setError, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { isActive: 1, prices: [pricesInitialState] }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "prices",
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);


    function onSubmit(data: any) {
        setLoading(true);
        data.isActive = data?.isActive ? 1 : 0;
        data.category =  data?.category?.value;
        data.role =  data?.role?.value;
        data.prices = JSON.stringify(data?.prices);
        if (formMode === 'update') data.uuid = selectedData?.uuid;
        const apiFunc = formMode === 'create' ? 'createSubscriptionPlan' : 'updateSubscriptionPlan';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "subscription-plan" }));
                closeModal();
                refreshData();
            })
            .catch((error: any) => {
                setLoading(false);
                handleAxiosError(error, toastMessage, formatMessage, setError);
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                title: selectedData.title,
                descriptions: selectedData.descriptions,
                role: roleOptionList?.find(item => item.value == selectedData.role),
                category: categoryOptionList?.find(item => item.value == selectedData.category),
                prices: selectedData.prices ? JSON.parse(selectedData.prices) : [pricesInitialState],
                features: selectedData.features,
                isActive: selectedData.isActive,
                order: selectedData.order,
            });
        }

    }, [formMode === 'update', selectedData]);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='md'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "title" })}
                        name="title"
                        control={control}
                        error={errors?.title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomSelect
                        options={categoryOptionList}
                        label={formatMessage({ id: "category" })}
                        name="category"
                        control={control}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomSelect
                        options={roleOptionList}
                        label={formatMessage({ id: "role" })}
                        name="role"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "description" })}
                        name="descriptions"
                        control={control}
                        minRows={6}
                    />
                </Grid>
                <Grid item xs={12}>
                    {fields.map((field, index) => (
                        <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                            <CustomTextBox
                                type="number"
                                label={formatMessage({ id: "plan-period" })}
                                name={`prices.${index}.planPeriod`}
                                control={control}
                            />
                            <CustomTextBox
                                label={formatMessage({ id: "period-type" })}
                                name={`prices.${index}.periodType`}
                                control={control}
                            />
                            <CustomTextBox
                                label={formatMessage({ id: "amount" })}
                                name={`prices.${index}.amount`}
                                type="number"
                                control={control}
                            />
                            <IconButton onClick={() => remove(index)}>
                                <Delete color='error' />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        startIcon={<Add />}
                        variant='contained'
                        onClick={() => append(pricesInitialState)}
                    >
                        Add Prices
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <CustomTextEditor
                        formLabel='Features'
                        name="features"
                        defaultValue={watch('features')}
                        error={errors?.features}
                        onChange={(editor: any) => setValue('features', editor)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        type='number'
                        label={formatMessage({ id: "order" })}
                        name="order"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "status" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;